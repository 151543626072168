
import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiMpWeChatConfigEdit, apiMPWeChatConfigInfo } from '@/api/channel/mp_wechat'
import { copyClipboard } from '@/utils/util'

@Component({
    components: {
        MaterialSelect
    }
})
export default class MPWechatIndex extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        name: '', // 公众号名称
        original_id: '', // 原始id
        qr_code: '', // 二维码
        app_id: '', // APP ID
        app_secret: '', // App Secret
        url: '', // URL
        token: '', // Token
        encoding_aes_key: '', // Encoding AES Key
        encryption_type: '', // 消息加密方式: 1-明文模式 2-兼容模式 3-安全模式
        business_domain: '', // 业务域名
        js_secure_domain: '', // JS接口安全域名
        web_auth_domain: '', // 网页授权域名
        // xhs_app_id: '', // 小红书 APP ID
        // xhs_app_secret: '', // 小红书 App Secret
    }

    // 表单验证
    rules: object = {
        app_id: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        app_secret: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        encryption_type: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        // xhs_app_id: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        // xhs_app_secret: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    }

    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    initFormData() {
        apiMPWeChatConfigInfo()
            .then(res => {
                // 表单同步于接口响应数据
                for (const key in res) {
                    if (!this.form.hasOwnProperty(key)) {
                        continue
                    }
                    this.form[key] = res[key]
                }
            })
            .catch(() => {
                this.$message.error('数据加载失败，请刷新重载')
            })
    }

    // 重置表单数据
    onResetFrom() {
        this.initFormData()
        this.$message.info('已重置数据')
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement
        console.log(refs.validate((valid: boolean): boolean => valid))

        refs.validate((valid: boolean) => {
            if (!valid) {
                return this.$message.error('请完善信息')
            }
            const loading = this.$loading({ text: '保存中' })
            const params: any = { ...this.form }

            delete params.url
            delete params.business_domain
            delete params.js_secure_domain
            delete params.web_auth_domain

            apiMpWeChatConfigEdit({
                ...params
            })
                .then(() => {
                    this.$message.success('保存成功')
                })
                .catch(() => {
                    this.$message.error('保存失败')
                })
                .finally(() => {
                    loading.close()
                })
        })
    }

    handleCopy(value: string) {
        copyClipboard(value)
            .then(() => {
                this.$message.success('复制成功')
            })
            .catch(err => {
                this.$message.error('复制失败')
                console.log(err)
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.initFormData()
    }

    /** E Life Cycle **/
}
